<template>
    <iframe class="airtable-embed airtable-dynamic-height"
            :src="src"
            frameborder="0"
            onmousewheel=""
            width="100%"
            height="533"
            style="background: transparent;"
    ></iframe>
</template>

<script>
    const SCRIPT_ID_PREFIX = 'airtable-embed-'
    const LIB = 'https://static.airtable.com/js/embed/embed_snippet_v1.js'

    export default {
      props: {
        src: { type: String },
      },
      data () {
        return {
          scriptId: null
        }
      },
      mounted () {
        const id = `${SCRIPT_ID_PREFIX}${Math.random}`
        const script = document.createElement('script')
        script.src = LIB
        script.id = id
        this.scriptId = id
        document.head.appendChild(script)
      },
      beforeDestroy () {
        document.head.removeChild(document.getElementById(this.scriptId))
      }
    }
</script>
