<template>
    <div class="payment-request">
        <AirtableEmbed :src="src" />
    </div>
</template>

<script>
    import AirtableEmbed from './AirtableEmbed.vue'
    const src = 'https://airtable.com/embed/shrS19ly7tnwnRdlx?backgroundColor=blue'

    export default {
      components: { AirtableEmbed },
      data () {
        return { src }
      },
    }
</script>
